import { addState } from '../../lib/add-state'
import { VideoInfoFragment as Video } from '../../api/types'

import { VideosUI } from './ui'

export type Props = {
    videos: Video[]
    discogsId?: number
    editUrl?: string
}

export type State = {
    playing: boolean
    index: number | null
    error: Error | null
}

export type Action =
    | { type: 'select'; index: number }
    | { type: 'error'; error: Error }
    | { type: 'play' }
    | { type: 'pause' }
    | { type: 'index'; index: number }

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'select':
            return {
                ...state,
                index: action.index,
                playing: true,
            }
        case 'error':
            return { ...state, error: action.error }
        case 'play':
            return { ...state, playing: true }
        case 'pause':
            return { ...state, playing: false }
        case 'index':
            return { ...state, index: action.index }
        default:
            return state
    }
}

function initial(props: Props): State {
    return {
        index: null,
        error: null,
        playing: false,
    }
}

export const Videos = addState(VideosUI, reducer, initial)
