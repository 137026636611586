export function script(): Promise<typeof window.YT> {
    return new Promise(function (resolve: (youtube: typeof window.YT) => void): void {
        /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
        if (window?.YT?.Player) {
            resolve(window.YT)
            return
        }

        // @ts-expect-error: We're defining this global
        window.onYouTubeIframeAPIReady = function (): void {
            resolve(window.YT)
        }

        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'

        // @ts-expect-error: TypeScript gets append wrong
        document.body.append(tag)
    })
}
