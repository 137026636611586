export function formatDuration(seconds: number): string {
    const sec = seconds % 60
    const min = ((seconds - sec) / 60) % 60
    const hours = (seconds - sec - 60 * min) / 3600

    const r = []
    if (hours !== 0) {
        r.push(hours)
    }

    r.push(hours === 0 ? min : pad(min))
    r.push(pad(sec))

    return r.join(':')
}

function pad(x: number): string {
    if (x >= 10) {
        return x.toString()
    }

    return `0${x}`
}

export function formatDurationAttribute(seconds: number): string {
    const duration = formatDuration(seconds).split(':')
    const attrib = String()
    if (duration.length === 3) {
        return attrib.concat(duration[0].toString(), 'H', duration[1].toString(), 'M', duration[2].toString(), 'S')
    }

    return attrib.concat(duration[0].toString(), 'M', duration[1].toString(), 'S')
}
